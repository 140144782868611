@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Condensed', sans-serif;
  background-color: #3f6d70;


  /* --c : rgba(255,255,255,0.7);
  --t : transparent;
  background-image:
  repeating-linear-gradient(45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0),
  repeating-linear-gradient(-45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0),
  linear-gradient(to bottom right, #FC354C, #0ABFBC); */
}

button {
  cursor: pointer;
}
