.Footer {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  /* font-size: 1rem; */
}

.Footer img {
  height: 80%;
}