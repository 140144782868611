.Switcher {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.Switcher span {
  margin: 0 20px;
  color: #fff;  
}

.Switcher button {
  width: 120px;
  background-color: #fff;
  border: 1px solid #FF9E00;
  border-radius: 7px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1rem;
}

.Switcher button:focus {
  outline: none;
}