.Hashtags {
    margin: 0 auto;
    padding: 0 25px;
    display: grid;
    grid-gap: 10px;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    grid-auto-rows: 75px;
    max-width: 1000px;
}

@media only screen and (min-width: 375px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }
}

@media only screen and (min-width: 475px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-auto-rows: 125px;
  }
}

@media only screen and (min-width: 575px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 150px;
  }
}

@media only screen and (min-width: 835px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-auto-rows: 175px;
  }
}