.ImageSelector {
  margin: 1rem auto;
  height: 3rem;
  display: flex;
  justify-content: center;
  max-width: 80vw;
}

.ImageSelector-input-link, .ImageSelector-file {
  flex-shrink: 0;
  /* border: 1px solid #FF55B5; */
  border: 1px solid #FF9E00;
  margin: 0;
  padding: 0;
  height: calc(3rem - 2px);
  min-width: 0;
  width: 250px;
  font-size: 1rem;
  vertical-align: middle;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.ImageSelector-file {
  background-color: #fff;
  line-height: 3rem;
  cursor: pointer;
}

.ImageSelector-label {
  cursor: pointer;
}

.ImageSelector-button {
  flex-shrink: 0;
  border: 5px solid white;
  height: 3rem;
  width: 3rem;
  padding: 2px;
  border-top-left-radius: 25%;
  border-bottom-left-radius: 25%;  
  /* background-color: #ff55b5; */
  background-color: #ff9e00;
}

.ImageSelector-input-link:focus, .ImageSelector-button:focus {
  outline: none;
}

.ImageSelector-button img {
  width: 100%;
  vertical-align: middle;
}

.ImageSelector-input-file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

@media only screen and (min-width: 375px) {
  .ImageSelector-input-link, .ImageSelector-file {
    width: 300px;
  }
}

@media only screen and (min-width: 750px) {
  .ImageSelector-input-link, .ImageSelector-file {
    width: 400px;
  }
}