@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cookie&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Condensed', sans-serif;
  background-color: #3f6d70;


  /* --c : rgba(255,255,255,0.7);
  --t : transparent;
  background-image:
  repeating-linear-gradient(45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0),
  repeating-linear-gradient(-45deg, var(--c) 0, var(--c) 20px, var(--t) 20px, var(--t) 32px, var(--c) 32px, var(--c) 44px, var(--t) 44px, var(--t) 56px, var(--c) 56px, var(--c) 68px, var(--t) 68px, var(--t) 80px, var(--c) 0),
  linear-gradient(to bottom right, #FC354C, #0ABFBC); */
}

button {
  cursor: pointer;
}

.ImageDisplay img {
    max-width: 70vw;
    max-height: 25vh;
    margin: 2rem;
    margin-bottom: 3rem;
    /* border: 2rem solid #F4F4F4;
    border-radius: 1rem; */
    box-shadow: 0 0 0 2rem #e2e1e1;
    border-radius: .5rem;
}
.Switcher {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.Switcher span {
  margin: 0 20px;
  color: #fff;  
}

.Switcher button {
  width: 120px;
  background-color: #fff;
  border: 1px solid #FF9E00;
  border-radius: 7px;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1rem;
}

.Switcher button:focus {
  outline: none;
}
.ImageSelector {
  margin: 1rem auto;
  height: 3rem;
  display: flex;
  justify-content: center;
  max-width: 80vw;
}

.ImageSelector-input-link, .ImageSelector-file {
  flex-shrink: 0;
  /* border: 1px solid #FF55B5; */
  border: 1px solid #FF9E00;
  margin: 0;
  padding: 0;
  height: calc(3rem - 2px);
  min-width: 0;
  width: 250px;
  font-size: 1rem;
  vertical-align: middle;
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.ImageSelector-file {
  background-color: #fff;
  line-height: 3rem;
  cursor: pointer;
}

.ImageSelector-label {
  cursor: pointer;
}

.ImageSelector-button {
  flex-shrink: 0;
  border: 5px solid white;
  height: 3rem;
  width: 3rem;
  padding: 2px;
  border-top-left-radius: 25%;
  border-bottom-left-radius: 25%;  
  /* background-color: #ff55b5; */
  background-color: #ff9e00;
}

.ImageSelector-input-link:focus, .ImageSelector-button:focus {
  outline: none;
}

.ImageSelector-button img {
  width: 100%;
  vertical-align: middle;
}

.ImageSelector-input-file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

@media only screen and (min-width: 375px) {
  .ImageSelector-input-link, .ImageSelector-file {
    width: 300px;
  }
}

@media only screen and (min-width: 750px) {
  .ImageSelector-input-link, .ImageSelector-file {
    width: 400px;
  }
}
.ButtonCopy {
    position: relative;
}

.ButtonCopy button {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  color: #2b2b2b;
}

.ButtonCopy button:focus {
    outline: none;
}

.ButtonCopy-popup {
    padding: 5px;
    height: 20px;
    width: 50px;
    color: #000;
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    background-color: #e6e6e6;
    align-self: start;
    justify-self: center;
    position: absolute;
    top: -40px;
    right: calc(50% - 30px);
}

.ButtonCopy-popup:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #bbbbbb transparent transparent transparent;
}


.Hashtag-ButtonCopy button {
  border-radius: 50%;
  box-shadow:inset 30px 30px 75px rgba(255, 85, 181, 0.1),
              inset 0px 0px 15px rgba(0,0,0,.5),
              inset 0px 0px 55px rgba(255,255,255,.5),
              inset 3px 3px 5px rgba(255, 85, 181, 0.5),
              0 0 25px rgba(255,255,255,.75);
  background:rgba(255, 255, 255, 0.4);
  border: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  padding-top: calc(50% - 6px);
  padding-bottom: calc(50% - 6px);
  padding-right: 10px;
  padding-left: 10px;
}

@media only screen and (min-width: 375px) {
  .Hashtag-ButtonCopy button {
    font-size: 16px;
    line-height: 16px;
    padding-top: calc(50% - 8px);
    padding-bottom: calc(50% - 8px);
  }
}

@media only screen and (min-width: 475px) {
  .Hashtag-ButtonCopy button {
    font-size: 20px;
    line-height: 20px;
    padding-top: calc(50% - 10px);
    padding-bottom: calc(50% - 10px);
  }
}

@media only screen and (min-width: 575px) {
  .Hashtag-ButtonCopy button {
    font-size: 22px;
    line-height: 22px;
    padding-top: calc(50% - 11px);
    padding-bottom: calc(50% - 11px);
  }
}

@media only screen and (min-width: 835px) {
  .Hashtag-ButtonCopy button {
    font-size: 26px;
    line-height: 26px;
    padding-top: calc(50% - 13px);
    padding-bottom: calc(50% - 13px);
  }
}
.Hashtags {
    margin: 0 auto;
    padding: 0 25px;
    display: grid;
    grid-gap: 10px;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    grid-auto-rows: 75px;
    max-width: 1000px;
}

@media only screen and (min-width: 375px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-rows: 100px;
  }
}

@media only screen and (min-width: 475px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    grid-auto-rows: 125px;
  }
}

@media only screen and (min-width: 575px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 150px;
  }
}

@media only screen and (min-width: 835px) {
  .Hashtags {
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    grid-auto-rows: 175px;
  }
}
.Footer {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  /* font-size: 1rem; */
}

.Footer img {
  height: 80%;
}
.App {
  text-align: center;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 3rem;
}

h1 {
  font-family: 'Cookie', cursive;
  color: #fff;
  font-size: 3.5rem;
  font-weight: 200;
  margin: 0;
  padding-top: 1rem;
  text-decoration: underline;
  /* text-decoration-color: #FF55B5; */
  -webkit-text-decoration-color: #FF9E00;
          text-decoration-color: #FF9E00;
  
}

h2 {
  padding: 1rem .5rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
}

.AllHashtagsCopy button {
  margin: 1rem;
  margin-top: 0;
  padding: .5rem;
  background-color: #fff;
  /* border: 3px solid #FF55B5; */
  border: 3px solid #FF9E00;
  border-radius: .5rem;
  font-size: 13px;
}

@media only screen and (min-width: 475px) {
  .AllHashtagsCopy button {
    font-size: 16px;
  }
}

@media only screen and (min-width: 750px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2rem;
  }
}
